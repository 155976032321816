import React, {useState, useEffect} from "react";
import styles from "./ProjectsListWeb.module.css"

import ProjectWeb from "./Project/ProjectWeb";

const ProjectsListWeb = (props) => {

    const [projectsData] = useState(
        [
        {
                        title: 'Davinciink',
                        description: '',
                        thumbnail: '/img/projects/web/Davinciink/Thumbnail.jpg',
                        images: [
                            "/img/projects/web/Davinciink/screen0L.jpg",
                            "/img/projects/web/Davinciink/screen0.jpg",
                            "/img/projects/web/Davinciink/screen1.jpg",
                            "/img/projects/web/Davinciink/screen2.jpg",
                            "/img/projects/web/Davinciink/screen3.jpg",
                            "/img/projects/web/Davinciink/screen4.jpg",
                        ],
                        link: "https://davanciink.com/"
                    },
            {
                title: 'Veterans Online',
                description: '',
                thumbnail: '/img/projects/web/VeteransOnline/Thumbnail.jpg',
                images: [
                    "/img/projects/web/VeteransOnline/Untitled0.jpg",
                    "/img/projects/web/VeteransOnline/Untitled1.jpg",
                    "/img/projects/web/VeteransOnline/Untitled2.jpg",
                    "/img/projects/web/VeteransOnline/Untitled3.jpg",
                ],
                link: "https://veterans-online.com/"
            },
            {
                title: 'Veterans Online Blog',
                description: '',
                thumbnail: '/img/projects/web/VeteransOnlineBlog/Thumbnail.jpg',
                images: [
                    "/img/projects/web/VeteransOnlineBlog/Untitled0.jpg",
                    "/img/projects/web/VeteransOnlineBlog/Untitled1.jpg",
                    "/img/projects/web/VeteransOnlineBlog/Untitled2.jpg",
                ],
                link: "https://blog.veterans-online.com/blog"
            },
            {
                title: 'Nuked Cockroach',
                description: '',
                thumbnail: '/img/projects/web/NukedCockroach/Thumbnail.jpg',
                images: [
                    "/img/projects/web/NukedCockroach/Untitled0.jpg",
                    "/img/projects/web/NukedCockroach/Untitled1.jpg",
                    "/img/projects/web/NukedCockroach/Untitled2.jpg",
                    "/img/projects/web/NukedCockroach/Untitled3.jpg",
                    "/img/projects/web/NukedCockroach/Untitled4.jpg"
                ],
                link: "https://www.nukedcockroach.com/"
            },
            {
                title: 'Cap-Olives',
                description: '',
                thumbnail: '/img/projects/web/CapOlives/thumbnail.jpg',
                images: [
                    "/img/projects/web/CapOlives/screen0.jpg",
                    "/img/projects/web/CapOlives/screen1.jpg",
                    "/img/projects/web/CapOlives/screen2.jpg",
                    "/img/projects/web/CapOlives/screen3.jpg",
                    "/img/projects/web/CapOlives/screen4.jpg",
                    "/img/projects/web/CapOlives/screen5.png",
                    "/img/projects/web/CapOlives/screen6.png",
                    "/img/projects/web/CapOlives/screen7.png",
                    "/img/projects/web/CapOlives/screen8.png",
                    "/img/projects/web/CapOlives/screen9.jpg",
                    "/img/projects/web/CapOlives/screen10.png",
                ],
                link: "http://olive-fruitsec.com/"
            },
            {
                title: 'TSI',
                description: '',
                thumbnail: '/img/projects/web/TSI/Thumbnail.jpg',
                images: [
                    "/img/projects/web/TSI/Untitled0.jpg",
                    "/img/projects/web/TSI/Untitled1.jpg",
                    "/img/projects/web/TSI/Untitled2.jpg",
                    "/img/projects/web/TSI/Untitled3.jpg",
                ],
                link: "http://tsi.tn/"
            },
            {
                title: 'Filmmaker Portfolio',
                description: '',
                thumbnail: "/img/projects/web/YoussefKsentini/Thumbnail.jpg",
                images: [
                    "/img/projects/web/YoussefKsentini/Untitled0.jpg",
                    "/img/projects/web/YoussefKsentini/Untitled1.png",
                    "/img/projects/web/YoussefKsentini/Untitled2.png",
                    "/img/projects/web/YoussefKsentini/Untitled3.png",
                ],
                link: "http://YoussefKsentini.com"
            }]
    )

    const [index, setIndex] = useState(0)
    const [projectDataArray, setProjectDataArray] = useState([]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setProjectDataArray(projectDataArray.concat(projectsData[index]))
            if (index < projectsData.length - 1)
                setIndex(index + 1)

        }, 300);

        return () => clearTimeout(timeout)
// eslint-disable-next-line
    }, [index])


    let projects = projectDataArray.map(project => (
        <ProjectWeb key={project.title} thumbnail={project.thumbnail} title={project.title}
                    description={project.description}
                    images={project.images}
                    link={project.link}/>
    ))

    let styleClasses = [styles.ProjectsList];
    if (props.styles)
        styleClasses.push(props.styles)

    return (
        <div className={styleClasses.join(' ')}>
            {projects}
        </div>
    )
}

export default ProjectsListWeb;