import React, {useState} from "react";
import styles from './ProjectGaming.module.css';
import iconPlayBtn from '../../../../../assets/Icons/IconPlayBtn.gif'

const ProjectGaming = (props) => {


    const [elementHover, setElementHover] = useState(false);

    let classesProjectContainer = [styles.ProjectContainer];
    let classesThumbnailImg = [styles.ThumbnailImg];
    let classesImageBackground = [styles.PlayIconHoverBackground];
    // let classesTitle = [styles.Title];
    // let classesDescription = [styles.Description];

    const onHoverElementHandler = (value) => {

        setElementHover(value);

    }

    if (elementHover) {
        classesProjectContainer.push(styles.ProjectContainerHover);
        classesThumbnailImg.push(styles.ThumbnailImgHover);
        classesImageBackground.push(styles.PlayIconHoverEntryAnimation);
        // classesTitle.push(styles.TitleHover);
        // classesDescription.push(styles.DescriptionHover);
    } else {
        classesProjectContainer = [styles.ProjectContainer];
        classesThumbnailImg = [styles.ThumbnailImg];
        // classesTitle = [styles.Title];
        // classesDescription = [styles.Description];
        classesImageBackground = [styles.PlayIconHoverBackground];

    }


    return (
        <a href={props.link} target="_blank" rel="noreferrer">
            <div
                className={classesProjectContainer.join(' ')}
                onMouseEnter={() => onHoverElementHandler(true)}
                onMouseLeave={() => onHoverElementHandler(false)}>
                <div className={styles.ProjectContent}>
                    <div className={styles.ImageZone}>
                        <div className={styles.Thumbnail}>
                            <img className={classesThumbnailImg.join(' ')}
                                 src={props.thumbnail}
                                 alt=""/>
                            <div className={classesImageBackground.join(' ')}>

                                <img className={styles.PlayIconHover} src={iconPlayBtn} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.TextZone}>
                        <div className={styles.GameName}>
                            {props.title}
                        </div>
                        <div className={styles.Years}>{props.duration}</div>

                        <div className={styles.Title}>
                            Description
                        </div>
                        <div className={styles.Description}>
                            {props.description.split('\n').map(function(item,i) {
                                return (
                                <div style={{paddingBottom : 5,paddingTop:5}} key={i}>
                            {item}
                                <br/>
                                </div>
                                )
                            })}
                        </div>
                    </div>

                </div>

            </div>
        </a>
    )
}

export default ProjectGaming;