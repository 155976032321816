import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import styles from './LogoMax.module.scss';
import logoMaxIcon from '../../assets/LogoMaxSimple.png'
import logoMaxEffectIcon from '../../assets/LogoMaxEffect.png'
import lightningEffect from '../../assets/LightningEffect.gif'
import smokeEffectSoftLeft from '../../assets/SmokeEffectSoftLeft.gif'
import smokeEffectFullLeft from '../../assets/SmokeEffectFullLeft.gif'
import smokeLanding from '../../assets/SmokeLandingOnce.gif';
import iconArrowLight from '../../assets/Icons/IconArrowLight.png'
import {Transition} from 'react-transition-group';
import * as actions from '../../store/actions/actions'

const LogoMax = (props) => {

    const [playLandingEffect, setPlayLandingEffect] = useState(props.playEntryAnimation);
    const dispatch = useDispatch();
    const onHomEffect = (value) => dispatch(actions.HomeEffect(value));

    const homeEffect = useSelector(state => {
        return state.homeEffect;
    });

    const nodeRef = React.useRef(null);
    const animationTiming = {
        enter: 4000,
        exit: 0
    };

    const [loginHover, setLoginHover] = useState(false);
    const isFirstRun = useRef(true);

    let commonClasses = [styles.EntryLogo];
    let logoClasses = [styles.Logo, styles.LogoExited];
    let effectClasses = [styles.LogoLight, styles.LightEffectExited];


    if (props.playEntryAnimation && !isFirstRun.current) {
        commonClasses.push(styles.EntryLogoAnim)
        setTimeout(() => {
            setPlayLandingEffect(true);
            setTimeout(() => {
                setPlayLandingEffect(false);
            }, 2500);
        }, 300);
    }

    useEffect(() => {

        if (isFirstRun.current) {
            isFirstRun.current = false;
        }

        return () => {
            // isFirstRun.current = false;
        }
    }, [props.playEntryAnimation, playLandingEffect])

    const onLogoEnterHandler = () => {
        setLoginHover(true);
    }

    const onLogoExitHandler = () => {
        setLoginHover(false);
    }
    return (
        <div className={styles.LogoContainer}>
            <div
                className={[styles.HoverMeContainer, loginHover ? styles.HoverMeHide : !isFirstRun.current && !props.playEntryAnimation ? styles.HoverMeShow : styles.HoverMeHide].join(' ')}>
                <div className={styles.HoverText}>
                    HOVER & HOLD
                </div>
                <div className={styles.IconsArrow}>
                    <div className={[styles.IconArrow, styles.IconArrowAnim1].join(' ')}>
                        <img src={iconArrowLight} alt=""/>
                    </div>
                    <div className={[styles.IconArrow, styles.IconArrowAnim2].join(' ')}>
                        <img src={iconArrowLight} alt=""/>
                    </div>
                    <div className={[styles.IconArrow, styles.IconArrowAnim3].join(' ')}>
                        <img src={iconArrowLight} alt=""/>
                    </div>
                </div>
            </div>
            <Transition
                nodeRef={nodeRef}
                in={loginHover}
                timeout={animationTiming}
                onEntered={() => onHomEffect(true)}
                onExited={() => onHomEffect(false)}
            >
                {state => {

                    if (!isFirstRun.current && !props.playEntryAnimation)
                        switch (state) {
                            case 'entering' :
                                commonClasses = [styles.CommonEffect];
                                commonClasses.push(styles.CommonEffectEntering);
                                logoClasses = [styles.Logo]
                                logoClasses.push(styles.LogoEntering);
                                effectClasses = [styles.LogoLight];
                                effectClasses.push(styles.LightEffectEntering);
                                break;
                            case 'entered' :
                                commonClasses = [styles.CommonEffect];
                                commonClasses.push(styles.CommonEffectEntered);
                                logoClasses = [styles.Logo]
                                logoClasses.push(styles.LogoEntered);
                                effectClasses = [styles.LightEffectEntered];
                                effectClasses = [styles.LogoLight];
                                effectClasses.push(styles.LightEffectEntered);
                                break;
                            case 'exiting' :
                                commonClasses = [styles.CommonEffect];
                                commonClasses.push(styles.CommonEffectExiting);
                                logoClasses = [styles.Logo]
                                logoClasses.push(styles.LogoExiting);
                                effectClasses = [styles.LightEffectExiting];
                                effectClasses = [styles.LogoLight];
                                effectClasses.push(styles.LightEffectExiting);
                                break;
                            case 'exited' :
                                commonClasses = [styles.CommonEffect];
                                commonClasses.push(styles.CommonEffectExited);
                                logoClasses = [styles.Logo]
                                logoClasses.push(styles.LogoExited);
                                effectClasses = [styles.LightEffectExited];
                                effectClasses = [styles.LogoLight];
                                effectClasses.push(styles.LightEffectExited);
                                break;
                            default:
                                commonClasses = [styles.CommonEffect];
                                logoClasses = [styles.Logo, styles.LogoExited];
                                effectClasses = [styles.LogoLight, styles.LightEffectExited];
                                break;

                        }
                    return (
                        <div
                            ref={nodeRef}
                            className={commonClasses.join(' ')}
                            onMouseEnter={onLogoEnterHandler}
                            onMouseLeave={onLogoExitHandler}>
                            <img className={logoClasses.join(' ')} src={logoMaxIcon} alt=""/>
                            <img className={effectClasses.join(' ')} src={logoMaxEffectIcon} alt=""/>
                            {state === "entered" ?
                                <img className={styles.LightningEffect} src={lightningEffect} alt=""/>
                                : null
                            }


                        </div>
                    )
                }}
            </Transition>
            {/*{*/}
            {/*    console.log("isFirstRun.current " + isFirstRun.current)*/}
            {/*}*/}
            {/*{*/}
            {/*    console.log("props.playEntryAnimation " + props.playEntryAnimation)*/}
            {/*}*/}
            {!isFirstRun.current && !props.playEntryAnimation ? !homeEffect ?
                <div>
                    <img className={styles.SmokeEffectSoftLeft} src={smokeEffectSoftLeft} alt=""/>
                    <img className={styles.SmokeEffectSoftRight} src={smokeEffectSoftLeft} alt=""/>
                </div>
                :
                <div>
                    <img className={styles.SmokeEffectFullLeft} src={smokeEffectFullLeft} alt=""/>
                    <img className={styles.SmokeEffectFullRight} src={smokeEffectFullLeft} alt=""/>
                </div>
                : playLandingEffect ? <div><img className={styles.SmokeLanding} src={smokeLanding} alt=""/></div> : null
            }

        </div>
    )
}

export default LogoMax;