import React from "react";
import styles from './NavigationItems.module.css'
import NavigationItem from "./NavigationItem/NavigationItem";

import iconHome from '../../../assets/Icons/Menu_Home.png';
import iconProfile from '../../../assets/Icons/Menu_Profile.png';
import iconSkills from '../../../assets/Icons/Menu_Skills.png';
import iconWork from '../../../assets/Icons/Menu_Work.png';
import iconContact from '../../../assets/Icons/Menu_Contact.png';

const navigationItems = (props) => {

    return (
        <div className={styles.NavigationItems}>
            <NavigationItem link="/" icon={iconHome} text="HOME" />
            <NavigationItem link="/about" icon={iconProfile} text="ABOUT"  />
            <NavigationItem link="/skills" icon={iconSkills} text="SKILLS"  />
            <NavigationItem link="/myWork" icon={iconWork} text="MY WORK"  />
            <NavigationItem link="/contactMe" icon={iconContact} text="CONTACT"  />
        </div>
    )
}
export default navigationItems;