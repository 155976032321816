import React, {useState} from "react";
import styles from "./MyWork.module.css";

import ProjectsListWeb from "./ProjectsList/ProjectListsWeb/ProjectsListWeb";
import {useSelector} from "react-redux";
import Tag from "../../components/UI/Tag/Tag";
import stylesTag from "../../components/UI/Tag/Tags.module.css";
import {Transition} from "react-transition-group";
import ProjectsListGaming from "./ProjectsList/ProjectListsGaming/ProjectsListGaming";
import ReactGA from "react-ga";

const MyWork = () => {
    ReactGA.initialize('UA-188085060-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });
    const [btnSection, setBtnSection] = useState([{state: true}, {state: false}]);

    const onClickBtnSection = (index) => {
        if (index === 0) {
            setBtnSection([{state: true}, {state: false}])
        } else {
            setBtnSection([{state: false}, {state: true}])
        }
    }

    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html'/>
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body'/>
            <div className={styles.MyWorkContainer}>

                <div className={styles.ProjectsListContainer}>
                    <div className={styles.SectionSelection}>
                        <div>
                            <span onClick={() => onClickBtnSection(0)}
                                  className={[styles.SectionBtn, btnSection[0].state ? styles.SectionBtnActive : null].join(' ')}>
                                <span
                                    className={styles.SectionSelectionBtnLeft}>GAMING</span></span>
                            <span onClick={() => onClickBtnSection(1)}
                                  className={[styles.SectionBtn, btnSection[1].state ? styles.SectionBtnActive : null].join(' ')}>
                                <span className={styles.SectionSelectionBtnRight}>WEB</span></span>
                        </div>
                        <div
                            className={[styles.UnderlineActiveBtn, btnSection[1].state ? styles.UnderlineActiveBtnRight : null].join(' ')}>
                        </div>
                    </div>
                    <div className={styles.SectionsContainer}>
                        <Transition
                            in={btnSection[0].state}
                            timeout={1000}
                        >
                            {state => {
                                return (
                                    <ProjectsListGaming
                                        styles={state === "entering" ||state === "entered"  ? styles.SectionContainerLeftOpen : state === "exiting" || state === "exited" ? styles.SectionContainerLeftClose : null

                                        }/>
                                )
                            }
                            }
                        </Transition>
                        <Transition
                            in={btnSection[1].state}
                            timeout={1000}
                        >{state => {
                            return (
                                <ProjectsListWeb
                                    styles={state === "entering" ||state === "entered"  ? styles.SectionContainerRightOpen : state === "exiting" ? styles.SectionContainerRightClose : null

                                    }/>
                            )


                        }}</Transition>
                    </div>
                </div>

            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body'/>
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html'/>
        </React.Fragment>
    return (
        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/myWork" ? pageContent : null : pageContent
            }
        </React.Fragment>
    )
}

export default MyWork;