import React from "react";

import sioudIcon from '../../assets/underLogoBig.png'
import styles from './TagSioud.module.css'

const tagSioud = () => (
    <div className={styles.TagSioud}>
        <img src={sioudIcon} alt=""/>
    </div>
)

export default tagSioud;