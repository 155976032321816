import React from "react"
import {MapContainer,TileLayer,Marker} from "react-leaflet";
import styles from './MyMap.module.css';
import indicatorIcon from '../../assets/Indicator.png';
import {icon} from "leaflet/dist/leaflet-src.esm";

const MyMap = () => {

    const position = [36.881600, 10.168625]
const indicator = new icon(
    {
        iconUrl:indicatorIcon,
        iconSize:[70,70]
    }
)
    return(
            <MapContainer className={styles.MyMapContainer} center={position} zoom={5}>
                <TileLayer
                    url="https://api.mapbox.com/styles/v1/veanyth/ckk1jww7k0v7z17qjeluq6vt6/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmVhbnl0aCIsImEiOiJja2sxa2UyNDMwcnlkMnFvNXBoa3JmMWhnIn0.m9XIRb2vnDf95sFRi-pQzA
"
                  attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
                />
                <Marker
                    icon={indicator}
                    position={position}
                />
            </MapContainer>
)
}
export default MyMap;