import React, {useState, useEffect} from "react";
import styles from './ProgressBars.module.css'

import ProgressBar from "./ProgressBar/ProgressBar";

const ProgressBars = () => {
    const [skillsData] = useState(
        [
            {
                name: "Unity",
                value: "79",
                icon: "/img/skill_Icons/Skill_Unity.png"
            },
            {
                name: "HTML/CSS",
                value: "90",
                icon: "/img/skill_Icons/Skill_HTML_CSS.png"
            },
            {
                name: "PHP",
                value: "75",
                icon: "/img/skill_Icons/Skill_PHP.png"
            },
            {
                name: "Javascript",
                value: "70",
                icon: "/img/skill_Icons/Skill_JS.png"
            },
            {
                name: "CSharp",
                value: "70",
                icon: "/img/skill_Icons/Skill_CSharp.png"
            },
            {
                name: "MySQL",
                value: "70",
                icon: "/img/skill_Icons/Skill_MySQL.png"
            },
            {
                name: "SQL Server",
                value: "65",
                icon: "/img/skill_Icons/Skill_SQL_Server.png"
            },
            {
                name: "CouchDB",
                value: "60",
                icon: "/img/skill_Icons/Skill_CouchDB.png"
            },
            {
                name: "Firebase",
                value: "60",
                icon: "/img/skill_Icons/Skill_Firebase.png"
            },
            {
                name: "React",
                value: "70",
                icon: "/img/skill_Icons/Skill_React.png"
            },
            {
                name: "Laravel",
                value: "75",
                icon: "/img/skill_Icons/Skill_Laravel.png"
            },
            {
                name: "Node JS",
                value: "50",
                icon: "/img/skill_Icons/Skill_Node.png"
            },
            {
                name: "Photoshop",
                value: "29",
                icon: "/img/skill_Icons/Skill_Photoshop.png"
            }
        ]
    )
    const [index, setIndex] = useState(0)

    const [skillDataArray, setSkillDataArray] = useState([]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSkillDataArray(skillDataArray.concat(skillsData[index]))
            if (index < skillsData.length - 1)
                setIndex(index + 1)
        }, 300);

        return () => clearTimeout(timeout)

// eslint-disable-next-line
    }, [index])
    let skills = skillDataArray.map(skill => (
            <ProgressBar key={skill.name} icon={skill.icon} name={skill.name} value={skill.value}/>
        )
    )
    return (
        <div className={styles.ProgressBarsContainer}>
            {skills}
        </div>
    )
}

export default ProgressBars;