import React from 'react';
import styles from './Button.module.css'
import {NavLink} from "react-router-dom";

const button = (props) => {

        const classes = [styles.Button, styles.Normal];
        if (props.classes)
            classes.push(props.classes)
        let btnContent;


        if (props.btnType) {
            if (props.btnType === "normal") {
                btnContent = (
                    <button
                        style={props.style}
                        disabled={props.disabled}
                        className={classes.join(' ')}>
                        {props.children}
                    </button>
                )
            }
        } else {
            btnContent = (
                <NavLink
                    to={props.link}
                    style={props.style}
                    disabled={props.disabled}
                    className={classes.join(' ')}>
                    {props.children}
                </NavLink>);
        }


        return (
            <React.Fragment>
                {btnContent}
            </React.Fragment>
        )
    }
;


export default button;
