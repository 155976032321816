import * as actionTypes from './actionTypes';


export const RouteTransition = (value) => {
    return {
        type: actionTypes.ROUTE_TRANSITION,
        value: value
    }
}

export const NextRoutePath = (value) => {
    return {
        type: actionTypes.NEXT_ROUTE_PATH,
        value: value
    }
}

export const HomeEffect = (value) => {
    return {
        type: actionTypes.HOME_EFFECT,
        value: value
    }
}

export const PageHeightScreen = (value) => {
    return {
        type: actionTypes.PAGE_HEIGHT_SCREEN,
        value: value
    }
}

export const ShowProjectModal = (show, title, description, images, link) => {
    return {
        type: actionTypes.SHOW_PROJECT_MODAL,
        show: show,
        title: title,
        description: description,
        images: images,
        link: link
    }
}

export const AddFeedback = (feedbackMessage) => {
    const id = Date.now() + Math.random();
    return {
        type: actionTypes.ADD_FEEDBACK,
        feedbackMessage: {
            id: id,
            typeMessage: feedbackMessage.typeMessage,
            message: feedbackMessage.message
        }
    }
}

export const RemoveFeedback = (id) => {

    return {
        type: actionTypes.REMOVE_FEEDBACK,
        id: id
    }
}