import React from "react";
import styles from "./AboutMe.module.css";
import chillingGIFWhite from '../../assets/ChillingWhite.gif'
import timelineImage from '../../assets/Timleline.png'
import Tag from "../../components/UI/Tag/Tag";
import stylesTag from "../../components/UI/Tag/Tags.module.css";
import {useSelector} from "react-redux";
import ReactGA from "react-ga";

const AboutMe = () => {
    ReactGA.initialize('UA-188085060-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });
    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html'/>
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body'/>
            <div className={styles.AboutMeContainer}>

                <div className={styles.TextZone}>
                    <h1>About Me</h1>
                    <br/>
                    <br/>
                    <p className={styles.P1}>Born in Tunisia,1989. I've grown up in the age of the retrogaming which made me
                        fascinated by
                        technologies
                        and virtual world.</p>
                    <p className={styles.P2}>
                        I've started my path of programmer as a web developer, but as I'm a video gamer since my
                        childhood I
                        couldn't hold myself for long before starting my career as a game developer.
                    </p>
                    <p className={styles.P3}>
                        Professionally connected with the web development industry and the gaming industry for
                        many
                        years.
                    </p>
                    <p className={styles.P4}>
                        Problem solver, well-organised person, strictly attentive to details. Fan of video
                        games, anime,
                        movies,
                        TV series, outdoor activities, a sporty person.
                    </p>
                    <img className={styles.TimelineImage} src={timelineImage} alt=""/>

                </div>
                <div className={styles.ImgZone}>
                    <img src={chillingGIFWhite} alt=""/>
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body'/>
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html'/>
        </React.Fragment>
    return (

        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/about" ? pageContent : null : pageContent
            }
        </React.Fragment>
    )
}

export default AboutMe;