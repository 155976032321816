import React from "react";
import styles from './Toggle.module.css'

const toggle = (props) => (
    <div className={styles.Toggle} onClick={props.click}>
        <div></div>
        <div></div>
        <div></div>
    </div>
)

export default toggle;