import React from 'react';

import styles from './Layout.module.css'
import TagSioud from '../../components/TagSioud/TagSioud';
import {useSelector} from "react-redux";
import ProjectModal from "../../containers/MyWork/ProjectModal/ProjectModal";
import {Transition} from "react-transition-group";

const Layout = props => {

    const showProjectModal = useSelector(state => {
        return state.showProjectModal;
    });

    const projectModal = <Transition
        mountOnEnter
        unmountOnExit
        in={showProjectModal.show}
        timeout={300}>{state => {
        const modalState = state === "entering" || state === "entered" ? "open" : "close"

        return (
            <ProjectModal
                state={modalState}
            />
        )
    }}
    </Transition>


    return (
        <React.Fragment>
            {projectModal}

            <div className={styles.Layout}>
                <div className={styles.Container}>

                    <main className={styles.Content}>
                        {props.children}
                    </main>

                    <TagSioud/>
                </div>
            </div>
        </React.Fragment>
    )
};

export default Layout;