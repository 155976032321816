import * as actionTypes from '../actions/actionTypes';

const initState = {
    routeTransition: false,
    nextRoutePath: "",
    homeEffect: false,
    pageHeightScreen: true,
    showProjectModal: {
        show: false,
        title: "",
        description: "",
        images: [],
        link: "#"
    },
    feedbackMessages: []
}


const reducer = (state = initState, action) => {

    switch (action.type) {
        case actionTypes.ROUTE_TRANSITION :
            return {
                ...state,
                routeTransition: action.value
            };
        case actionTypes.NEXT_ROUTE_PATH :
            return {
                ...state,
                nextRoutePath: action.value
            };
        case actionTypes.HOME_EFFECT :
            return {
                ...state,
                homeEffect: action.value
            };
        case actionTypes.PAGE_HEIGHT_SCREEN :

            return {
                ...state,
                pageHeightScreen: action.value
            };
        case actionTypes.SHOW_PROJECT_MODAL :
            return {
                ...state,
                showProjectModal: {
                    show: action.show,
                    title: action.title,
                    description: action.description,
                    images: action.images,
                    link: action.link
                }
            };

        case actionTypes.ADD_FEEDBACK :
            return {
                ...state,
                feedbackMessages: state.feedbackMessages.concat(action.feedbackMessage)
            };
        case actionTypes.REMOVE_FEEDBACK :
// const listMessagesUpdated = state.feedbackMessages.filter(item => item.id !== action.id);

            return {
                ...state,
                feedbackMessages: state.feedbackMessages.filter(item => item.id !== action.id)
            };


        default :
            return state;
    }
};

export default reducer