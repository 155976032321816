import React from "react";
import styles from './TransitionScreen.module.css'
import LogoIcon from '../../../assets/LogoMaxSimple.png'

const TransitionScreen = (props) => {
    let classesTransition = [styles.TransitionScreen];
    let classesLoadingBar = [styles.LoadingBar];
    if (props.playAnim) {
        classesTransition.push(styles.PlayAnimation);
        classesLoadingBar.push(styles.PlayLoadingAnimation);
    } else {
        classesTransition = [styles.TransitionScreen];
        classesLoadingBar = [styles.LoadingBar];
    }
    return (
        <div className={classesTransition.join(' ')}>
            <div className={styles.Content}>

                <div className={styles.Logo}>
                    <img src={LogoIcon} alt=""/>
                </div>
                <div className={styles.LoadingSection}>
                    <div className={classesLoadingBar.join(' ')}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransitionScreen;