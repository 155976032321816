import React from "react";
import styles from './NavigationItemsMobile.module.css'
import NavigationItemMobile from "./NavigationItemMobile/NavigationItemMobile";

import iconHome from '../../../assets/Icons/Menu_Home.png';
import iconProfile from '../../../assets/Icons/Menu_Profile.png';
import iconSkills from '../../../assets/Icons/Menu_Skills.png';
import iconWork from '../../../assets/Icons/Menu_Work.png';
import iconContact from '../../../assets/Icons/Menu_Contact.png';

const navigationItems = (props) => {

    const classes = [styles.NavigationItemsMobile, props.show ? styles.Open : styles.Close];


    return (
        <div className={classes.join(' ')}>
            <NavigationItemMobile link="/" icon={iconHome} text="HOME" clicked={props.clicked}/>
            <NavigationItemMobile link="/about" icon={iconProfile} text="ABOUT" clicked={props.clicked}/>
            <NavigationItemMobile link="/skills" icon={iconSkills} text="SKILLS" clicked={props.clicked}/>
            <NavigationItemMobile link="/myWork" icon={iconWork} text="MY WORK" clicked={props.clicked}/>
            <NavigationItemMobile link="/contactMe" icon={iconContact} text="CONTACT" clicked={props.clicked}/>
        </div>
    )
}
export default navigationItems;