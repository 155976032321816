import React, {useState, useCallback} from "react";
import {updateObject, checkValidity} from '../../shared/shared';
import {apikeys} from "../../shared/apikeys";
import emailjs from 'emailjs-com';

import styles from './ContactMe.module.css';
import stylesTag from "../../components/UI/Tag/Tags.module.css";
import "./leaflet.css";

import MyMap from "../../components/MyMap/MyMap";
import Tag from "../../components/UI/Tag/Tag";
import Button from '../../components/UI/Button/Button'
import FeedbackMessages from "../../components/UI/FeedbackMessage/FeedbackMessages";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions/actions";
import ReactGA from "react-ga";

const ContactMe = () => {
    ReactGA.initialize('UA-188085060-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });
    const [fields, setFields] = useState({
        name: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        email: {
            value: "",
            validation: {
                required: "true",
                email: "true",
            },
            valid: false,
            touched: false
        },
        subject: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        message: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        }
    });

    const [formIsValid, setFormIsValid] = useState(false);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const handleChange = (event, field) => {
        const updatedField = updateObject(fields[field], {
            value: event.target.value,
            valid: checkValidity(event.target.value, fields[field].validation),
            touched: true
        })
        const updatedFields = updateObject(fields, {[field]: updatedField});

        let formIsValid = true;
        for (let field in updatedFields) {
            formIsValid = updatedFields[field].valid && formIsValid;
        }
        setFormIsValid(formIsValid);
        setFields(updatedFields);
    }


    const validationClasses = (isValid, isTouched) => {
        if (isTouched && isValid)
            return [styles.Valid]
        else if (isTouched && !isValid)
            return [styles.Error]
    }

    const resetForm = () => {
        let updatedFields = {...fields};
        for (let field in updatedFields) {
            let clearedField = updateObject(fields[field],
                {
                    value: "",
                    validation: fields[field].validation,
                    valid: false,
                    touched: false

                })
            updatedFields = updateObject(updatedFields, {[field]: clearedField});
            console.log("test " + updatedFields[field].value);
        }

        setFields(updatedFields);
        setFormIsValid(false);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        addFeedbackMessage({typeMessage: "sending", message: "SENDING..."});
        emailjs.sendForm('gmail', apikeys.TEMPLATE_ID, event.target, apikeys.USER_ID)
            .then(result => {
                    if (result.text === "OK") {
                        addFeedbackMessage({typeMessage: "sent", message: "SENT!"});
                        resetForm();
                    }
                },
                error => {
                    addFeedbackMessage({typeMessage: "error", message: "SOMETHING WENT WRONG!"});
                })

    }
    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html'/>
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body'/>
            <div className={styles.ContactMeContainer}>
                <div className={styles.TextZone}>
                    <h1>
                        Contact Me
                    </h1>
                    <p className={styles.P1}>
                        I am interested in freelance opportunities – but since I have a full time job I'm especially
                        interested in small/medium projects.
                        However, if you have other request or question, don’t hesitate to contact me using below form
                        either.
                    </p>
                    <div className={styles.ContactForm}>
                        <form onSubmit={submitHandler} autoComplete={"off"}>
                            <ul>

                                <li className={[styles.Half, styles.ContactFormLI1Animation].join(' ')}>
                                    <input
                                        name="name"
                                        type="text" placeholder="Name"
                                        onChange={(event) => handleChange(event, "name")}
                                        value={fields["name"].value}/>
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["name"].valid, fields["name"].touched)].join(' ')}>
                                    </label>
                                </li>
                                <li className={[styles.Half, styles.ContactFormLI2Animation].join(' ')}>
                                    <input
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        onChange={(event) => handleChange(event, "email")}
                                        value={fields["email"].value}/>
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["email"].valid, fields["email"].touched)].join(' ')}>
                                    </label>
                                </li>
                                <li className={styles.ContactFormLI3Animation}>
                                    <input
                                        name="subject"
                                        type="text"
                                        placeholder="Subject"
                                        onChange={(event) => handleChange(event, "subject")}
                                        value={fields["subject"].value}/>
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["subject"].valid, fields["subject"].touched)].join(' ')}>
                                    </label>
                                </li>
                                <li className={styles.ContactFormLI4Animation}>
                                    <textarea name="message" id="" cols="30" rows="10"
                                              placeholder="Message"
                                              onChange={(event) => handleChange(event, "message")}
                                              value={fields["message"].value}
                                    />
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["message"].valid, fields["message"].touched)].join(' ')}>
                                    </label>
                                </li>
                            </ul>
                            <Button classes={styles.ButtonAnimation} btnType="normal" disabled={!formIsValid}
                                    style={{position: 'relative'}}>SEND</Button>
                        </form>
                    </div>
                </div>
                <div className={styles.MapZone}>
                    <MyMap/>
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body'/>
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html'/>
            <FeedbackMessages/>
        </React.Fragment>
    return (
        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/contactMe" ? pageContent : null : pageContent
            }
        </React.Fragment>
    )
}

export default ContactMe;