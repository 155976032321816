import React, {useState} from "react";
import styles from './ProjectModal.module.css'

import {useDispatch, useSelector} from "react-redux";
import * as actions from '../../../store/actions/actions'

const ProjectModal = (props) => {

    const modalData = useSelector((state) => {
        return state.showProjectModal;
    });

    const dispatch = useDispatch();
    const showProjectModal = (show,title,description,images,link) => dispatch(actions.ShowProjectModal(show,title,description,images,link))

    const styleClasses = []
    if (props.state && props.state === "open")
        styleClasses.push(styles.ProjectModalContainerOpen)
    else if (props.state && props.state === "close")
        styleClasses.push(styles.ProjectModalContainerClose)

    const onClickCloseBtnHandler = () => {
        showProjectModal(false,"","",[],"");
    }

    const [currentImage, SetCurrentImage] = useState(0);

    const images = modalData.images;
    const switchImageHandler = (value) => {
        if (value) {
            if (currentImage < images.length - 1)
                SetCurrentImage(currentImage + 1);
            else
                SetCurrentImage(0);
        } else {
            if (currentImage > 0)
                SetCurrentImage(currentImage - 1);
            else
                SetCurrentImage(images.length - 1);
        }
    }

    return (
        <div className={[styles.ProjectModalContainer, styleClasses].join(' ')}>
            <div className={styles.Backdrop}
                 onClick={() => onClickCloseBtnHandler()}>
            </div>
            <div className={styles.Visualizer}>
                <img src={images[currentImage]} alt=""/>
            </div>
            <div className={styles.LeftBtn}
                 onClick={() => switchImageHandler(false)}>
                <div className={styles.LeftBtnBar1}>
                </div>
                <div className={styles.LeftBtnBar2}>
                </div>
            </div>
            <div className={styles.RightBtn}
                 onClick={() => switchImageHandler(true)}>
                <div className={styles.RightBtnBar1}>
                </div>
                <div className={styles.RightBtnBar2}>
                </div>
            </div>

            <a className={styles.Link} target="_blank" rel="noreferrer" href={modalData.link}>
                <div className={styles.LinkZone}>
                    LINK HERE
                </div>
            </a>

            <div className={styles.CloseBtnContainer}
                 onClick={() => onClickCloseBtnHandler()}>
                <div className={styles.CloseBtnBar1}>
                </div>
                <div className={styles.CloseBtnBar2}>
                </div>
            </div>
        </div>
    );
};

export default ProjectModal;