import React from "react";

import styles from './LogoMin.module.css';
import logoIconMin from '../../assets/LogoMin.png';

const logoMin = (props) => {
    return (
        <div className={styles.LogoMin} >
            <img className={styles.LogoMinImg} src={logoIconMin} style={{height:props.height}} alt=""/>
        </div>
    )
}

export default logoMin;