import React, {useState,useCallback} from "react";
import styles from './ProjectWeb.module.css';
import {useDispatch} from "react-redux";
import * as actions from "../../../../../store/actions/actions";


const ProjectWeb = (props) => {

    const dispatch = useDispatch();

    const ShowProjectModal = useCallback((show, title, description, images,link) => {dispatch(actions.ShowProjectModal(show, title, description, images,link))},[dispatch])

    const onClickElementHandler = () => {

        ShowProjectModal(true, props.title, props.description, props.images,props.link);
    }

    const [elementHover, setElementHover] = useState(false);

    let classesProjectContainer = [styles.ProjectContainer];
    let classesThumbnailImg = [styles.ThumbnailImg];
    let classesTitle = [styles.Title];
    let classesDescription = [styles.Description];

    const onHoverElementHandler = (value) => {

        setElementHover(value);

    }

    if (elementHover) {
        classesProjectContainer.push(styles.ProjectContainerHover);
        classesThumbnailImg.push(styles.ThumbnailImgHover);
        classesTitle.push(styles.TitleHover);
        classesDescription.push(styles.DescriptionHover);
    } else {
        classesProjectContainer = [styles.ProjectContainer];
        classesThumbnailImg = [styles.ThumbnailImg];
        classesTitle = [styles.Title];
        classesDescription = [styles.Description];
    }


    return (
        <div
            className={classesProjectContainer.join(' ')}
            onMouseEnter={() => onHoverElementHandler(true)}
            onMouseLeave={() => onHoverElementHandler(false)}
            onClick={() => onClickElementHandler()}>
            <div className={styles.Thumbnail}>
                <img className={classesThumbnailImg.join(' ')} src={props.thumbnail} alt=""/>
            </div>
            <div className={classesTitle.join(' ')}>
                {props.title}
            </div>
            <div className={classesDescription.join(' ')}>
            {props.description.split(/[\r\n]+/).map(line => <div key={Math.random()}>{line}</div>)}
            </div>
        </div>
    )
}

export default ProjectWeb;