import React, {useState} from "react";
import styles from './Toolbar.module.css'
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import NavigationItemsMobile from '../Navigation/NavigationItemsMobile/NavigationItemsMobile';

import LogoMin from "../Logo/LogoMin";
import Toggle from '../../components/Toggle/Toggle';
import Backdrop from '../../components/UI/Backdrop/Backdrop';

const Toolbar = props => {

    const [toggle, setToggle] = useState(false);

    const ToggleHandler = () => {
        setToggle(!toggle);
    }
const closeBackdrop =()=> {
    setToggle(false);
};
    return (
        <React.Fragment>
            <header className={styles.Toolbar}>
                <LogoMin height="36"/>
                <div className={[styles.ToggleMobile, styles.MobileOnly].join(' ')}>
                    <Toggle click={ToggleHandler}/>

                </div>

                <nav className={styles.DesktopOnly}>
                    <NavigationItems/>
                </nav>
            </header>
            <div className={styles.MobileOnly}>
                <div className={styles.ToolbarMobileOnly}>
                    <NavigationItemsMobile show={toggle} clicked={closeBackdrop}/>
                </div>
                <Backdrop show={toggle} clicked={ToggleHandler}/>
            </div>
        </React.Fragment>
    )
}

export default Toolbar;