import React from "react";
import styles from './ProgressBar.module.css';
import {StyleSheet, css} from 'aphrodite/no-important';

const ProgressBar = (props) => {

    const progressKeyframes = {
            '0%': {
                width: 0
            },
            '100%': {
                width: props.value + '%'
            }
        }
    ;

    const styleAnimations = StyleSheet.create({
        progressAnim: {
            animationName: [progressKeyframes],
            animationDuration: '2s, 1200ms',
            animationFillMode: 'forwards',
        },
    });

    const progressFillClasses = [styles.FillBar, css(styleAnimations.progressAnim)].join(' ');

    const level = () => {
        switch (true) {
            case props.value > 10 && props.value < 30:
                return "Beginner";
            case props.value >= 30 && props.value < 70:
                return "Intermediate";
            case props.value >= 70 && props.value < 80:
                return "Advanced";
            case props.value >= 80 && props.value <= 100:
                return "Expert";
            default:
                return "¯\\_(ツ)_/¯";
        }
    }

    return (
        <div className={styles.ProgressBarContainer}>
            <div className={styles.LeftSection}>
                <div className={styles.Icon}>
                    <img src={props.icon} alt=""/>
                </div>
            </div>
            <div className={styles.RightSection}>
                <div className={styles.SkillName}>{props.name}</div>
                <div className={styles.Value}>{level()}</div>
                <div className={styles.FillBarBackground}>
                    <div className={progressFillClasses}></div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;