import React from "react";

import styles from './Tag.module.css'

const tag =props => (

<div
    className={[styles.Tag,props.classes].join(' ')}>
    &#60;{props.closed?'/':null}{props.text}&#62;</div>
)

export default tag;