import React from "react";
import styles from "./Skills.module.css";
import {NavLink} from 'react-router-dom';

import ProgressBars from './ProgressBars/ProgressBars';
import Tag from "../../components/UI/Tag/Tag";
import stylesTag from "../../components/UI/Tag/Tags.module.css";
import {useSelector} from "react-redux";
import ReactGA from "react-ga";

const Skills = () => {
    ReactGA.initialize('UA-188085060-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });

    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html'/>
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body'/>
            <div className={styles.SkillsContainer}>

                <div className={styles.TextZone}>
                    <h1>Skills & Experience</h1>
                    <br/>
                    <br/>
                    <p className={styles.P1}>I've acquired enough knowledge in Web Front-end and Back-end to call myself
                        a
                        full-stack developer.</p>
                    <p className={styles.P2}>I've been building small and medium web apps mainly with React for the
                        Front-end and SpringBoot/Laravel for the Back-end and I love putting efforts in animations and interactive
                        layouts.</p>
                    <p className={styles.P3}>I've turned into the Gaming industry and started as UI/UX Developer since I
                        had experience with Web Front End, and then put my focus on gameplay and more advanced fields.</p>

                    <p className={styles.P4}>Visit my <a className={styles.Navlink} target="_blank" rel="noreferrer"
                                                         href="https://www.linkedin.com/in/sioud-zied/">LinkedIn</a> profile
                        for
                        more
                        details or just <NavLink to={"contactMe"} className={styles.Navlink}>Contact
                            Me</NavLink>.</p>


                </div>
                <div className={styles.SkillsBarContainer}>
                    <ProgressBars/>
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body'/>
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html'/>
        </React.Fragment>

    return (
        <React.Fragment>

            <React.Fragment>
                {
                    routeTransition ? nextRoutePath !== "/skills" ? pageContent : null : pageContent
                }
            </React.Fragment>
        </React.Fragment>
    )
}

export default Skills;