import Layout from './hoc/Layout/Layout';
import {Route, Switch, Redirect} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from "react-transition-group";
import * as actions from './store/actions/actions';
import Home from './containers/Home/Home'
import AboutMe from './containers/AboutMe/AboutMe'
import Skills from './containers/Skills/Skills'
import MyWork from './containers/MyWork/MyWork'
import ContactMe from './containers/ContactMe/ContactMe'
import React, {useState} from "react";
import "./App.css";
import Toolbar from "./components/Toolbar/Toolbar";
import TransitionScreen from './components/UI/TransitionScreen/TransitionScreen';
import {useDispatch} from "react-redux";

const App = props => {
    const [transitionScreen, setTransitionScreen] = useState(false)
    const dispatch = useDispatch();
    const onRouteTransition = (value) => dispatch(actions.RouteTransition(value))
    const onNextRoutePath = (value) => dispatch(actions.NextRoutePath(value))
    const transitionIn = () => {
        setTransitionScreen(false);
        onRouteTransition(false);
    }
    const transitionOut = () => {

        setTransitionScreen(true);
        onRouteTransition(true);
    }
    return (
        <React.Fragment>
            <Route
                render={({location}) => {

                    onNextRoutePath(location.pathname)

                    return (
                        <React.Fragment>
                            <Toolbar/>
                            <TransitionGroup component={null}>
                                <CSSTransition
                                    key={location.key}
                                    // nodeRef={nodeRef}
                                    classNames="fade"
                                    onEntered={() => transitionIn()}
                                    onExiting={() => transitionOut()}
                                    timeout={{enter: 3000, exit: 500}}>
                                    <Layout>
                                        <Switch location={location}>
                                            <Route path="/about"
                                                   component={AboutMe}/>
                                            <Route path="/skills"
                                                   component={Skills}/>
                                            <Route path="/myWork"
                                                   component={MyWork}/>
                                            <Route path="/contactMe"
                                                   component={ContactMe}/>
                                            <Route path="/" exact
                                                   component={Home}/>}/>
                                            <Redirect to="/"/>
                                        </Switch>
                                    </Layout>
                                </CSSTransition>
                            </TransitionGroup>
                        </React.Fragment>)
                }}
            />
            <TransitionScreen playAnim={transitionScreen}/>
        </React.Fragment>
    );
}

export default App;
