import React, {useEffect, useState} from "react";
import ReactGA from 'react-ga'
import {useSelector} from "react-redux";
import styles from './Home.module.css'
import stylesTag from '../../components/UI/Tag/Tags.module.css';
import LogoMax from "../../components/Logo/LogoMax";
import Button from '../../components/UI/Button/Button';
import Tag from "../../components/UI/Tag/Tag";


const Home = () => {
    ReactGA.initialize('UA-188085060-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const homeEffect = useSelector(state => {
        return state.homeEffect;
    });
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });
    const [entryAnimation, setEntryAnimation] = useState(false);

    let classesTextZone = [styles.TextZone];
    let classesButton = [styles.ButtonAnimation];

    if (homeEffect) {
        classesTextZone.push(styles.TextZoneAnim);
        classesButton.push(styles.ButtonHomeEffect)
    } else
        classesTextZone = [styles.TextZone];

    const triggerEntryLandingTimer = () => {
        setTimeout(() => {
            setEntryAnimation(true);
            setTimeout(() => {
                setEntryAnimation(false);
            }, 3000)
        }, 3000)
    }

    useEffect(() => {

        triggerEntryLandingTimer();
        if (routeTransition) {
            clearTimeout(triggerEntryLandingTimer);
        }
        return () => {
            setEntryAnimation(false)
        }

    }, [routeTransition])


    const pageContent = <React.Fragment>
        <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html'/>
        <Tag classes={stylesTag.BodyTopTag} closed={false} text='body'/>
        <div className={styles.HomeContainer}>
            <div className={classesTextZone.join(' ')}>
                <h1>Hi,<br/>
                    I'm <span className={styles.ZCharacter} style={{color: "#08fdd8"}}>Z</span>ied
                    Sioud, <br/>
                    Sr Software Developer.</h1>
                <h2>Game Developer / Web Front End Developer / Web Back End Developer</h2>
                <Button link="/contactMe" classes={classesButton.join(' ')}>CONTACT ME</Button>
            </div>
            <div className={styles.LogoZone}>
                <LogoMax playEntryAnimation={entryAnimation}/>
            </div>
        </div>
        <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body'/>
        <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html'/>
    </React.Fragment>

    return (
        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/" ? pageContent : null : pageContent
            }
        </React.Fragment>
    );
}

export default Home;