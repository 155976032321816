import React, {useState} from "react";
import styles from './NavigationItemMobile.module.css'
import {NavLink} from 'react-router-dom';
import {Transition} from "react-transition-group";


const NavigationItem = props => {

    const nodeRef = React.useRef(null);

    const [showText, setShowText] = useState(false);

    const showMouseEnterHandler = () => {
        setShowText(true);
    }
    const showMouseExitHandler = () => {
        setShowText(false);
    }

    return (
        <Transition
            nodeRef={nodeRef}
            in={showText}
            timeout={200}>
            {state => {
                const showHover = state === 'entered' ? true : state !== 'exited' ? true : null;
                return (
                    <NavLink
                        ref={nodeRef}
                        to={props.link}
                        exact
                        className={styles.NavigationItemMobile}
                        activeClassName={styles.active}
                        onMouseEnter={showMouseEnterHandler}
                        onMouseLeave={showMouseExitHandler}
                        onClick={props.clicked}>
                        <p className={showHover ? styles.LinkEnter : styles.LinkExit}>{props.text}</p>
                        <img className={showHover ? styles.LinkExit : styles.LinkEnter} src={props.icon} alt=""/>

                    </NavLink>
                )
            }}
        </Transition>
    )
};

export default NavigationItem;