import React, {useState, useEffect, useRef, useCallback} from "react";
import styles from './FeedbackMessages.module.css';
import FeedbackMessage from "./FeedbackMessage/FeedbackMessage";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions/actions";

const FeedbackMessages = (props) => {


    const [listMessages, setListMessages] = useState([]);

    const feedbackMessage = useSelector(state => {
        return state.feedbackMessages[state.feedbackMessages.length - 1];
    });

    const isFirstRun = useRef(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const addFeedback = useCallback((feedbackMessage) =>
        addFeedbackHandler(feedbackMessage)
    );

    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const removeFeedback = useCallback((id) =>
        dispatch(actions.RemoveFeedback(id))
    );

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        addFeedback(feedbackMessage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackMessage])

    const removeFeedbackHandler = (id) => {

        removeFeedback(id);

    }

    const addFeedbackHandler = (feedbackMessage) => {

        if (feedbackMessage === undefined)
            return;

        setListMessages([...listMessages, feedbackMessage])

    }

    return (
        <div className={styles.FeedbackMessagesContainer}>
            {listMessages.map(feedbackMessage => {
                return (<FeedbackMessage
                    key={feedbackMessage.id}
                    id={feedbackMessage.id}
                    type={feedbackMessage.typeMessage}
                    message={feedbackMessage.message}
                    removeItem={removeFeedbackHandler}
                />)
            })}

        </div>
    )
}

export default FeedbackMessages;